// import L from "leaflet"
import { updateCoordinates } from "../helper-functions"
// list of places
export var places = {
  cafes: [
    {
      display: "Nescafe",
      img: "nesci.jpg",
      markers: [
        { name: "Nescafe", coords: updateCoordinates([-24.68, 38.36]) },
      ],
      p1:
        "Nescafe, or as it is fondly called “Nesci” is one of the most popular hangout spots for sports people because of it’s proximity to the sports complex. Whether one needs a healthy meal after rigorous practice sessions, or a cheeseburger on a lazy Saturday afternoon, this place has a provision to serve you all kinds of snacks.",
    },
    {
      display: "Alpahaar",
      img: "alpa.jpg",
      markers: [
        { name: "Alpahaar", coords: updateCoordinates([-42.98, 35.36]) },
      ],
      p1:
        "Located near Khosla International House(KIH), is one of the oldest canteens on the campus. The place has shifted a few times and despite its awkward location, it remains as one of the favourite hangout spots for students. Alpahaar promises to serve you one of the best Cheese Patty on the campus while you could also treat yourself with some sweets or paranthas here.",
    },
    {
      display: "Subway",
      img: "subway.jpg",
      markers: [{ name: "Subway", coords: updateCoordinates([-12.0, 30.0]) }],
      p1:
        "The institute has its own extension of Subway. Located in the Multi Activity Centre, Subway coupled with the Food court is a popular spot to grab something on the go or to have a quiet late dinner. It is a much needed alternative to the messes. ",
    },
    {
      display: "CCD",
      img: "ccd_out.jpg",
      markers: [{ name: "CCD", coords: updateCoordinates([-11.68, 29.5]) }],
      p1:
        "Situated on the ground floor of the Multi Activity Center (MAC) the place serves as a rendezvous for various group discussions, group meetings, ‘personal’ meetings and friendly hangouts. The place attracts a handsome amount of people because of two main reasons it’s highly subsidised prices and location.",
    },
    {
      display: "Satviko",
      img: "satviko.jpg",
      markers: [{ name: "Satviko", coords: updateCoordinates([-11.5, 30.3]) }],
      p1:
        "Satviko Idea Cafe is one among the first establishments in MAC. Located on the third floor of MAC, it is a pretty isolated cozy spot to relax and enjoy. The food pales in comparison to other places in the institute and is the primary reason for the cafe’s decline. It’s bakar room on the other hand is a cool place to hang out, the large panelled windows create a good vibe, especially in cosy weather.",
    },
    {
      display: "CBRI",
      img: "cbri.jpg",
      markers: [{ name: "CBRI", coords: updateCoordinates([-27.62, 48.67]) }],
      p1:
        "Situated near the Sarojini Bhawan, the CBRI canteen is the most-sought after place when one has missed the dinner or for one of the mafia sessions. This place is accompanied by a comfortable ground and serves the spiciest maggi and the biggest aaloo paranthas in Roorkee. However, they do not allow entry without an ID card and may not serve after 10.",
    },
  ],
  holy: [
    {
      display: "Saraswati Mandir",

      img: "smandir.jpg",
      markers: [
        {
          name: "Saraswati Mandir",
          coords: updateCoordinates([-18.06, 33.83]),
        },
      ],
      p1:
        "Saraswati Mandir, located beside the LBS ground is a temple of goddess Saraswati. All places of worship are the prime destination in the campus and add to its charm.",
    },
    {
      display: "St. John’s Church",

      img: "church.jpg",
      markers: [
        { name: "St. John’s Church", coords: updateCoordinates([-23, 14.67]) },
      ],
      p1:
        " It is located near the century gate and is one of the photogenic places on the campus.",
    },
    {
      display: "The Mosque",

      img: "mosque.jpg",
      markers: [
        { name: "The Mosque", coords: updateCoordinates([-15.78, 33.75]) },
      ],
      p1:
        "It is among the most peaceful places on campus, located near the Khosla International House.",
    },
  ],
  hostels: [
    {
      display: "Rajendra Bhawan",

      img: "hcanteen.jpg",
      markers: [
        { name: "Rajendra Bhawan", coords: updateCoordinates([-7.75, 23.97]) },
      ],
      p1:
        "While some of the hostel canteens like Rajiv Bhawan, Ravindra and Govind Bhawan are better than most of the eateries, the other bhawan canteen are good enough to satisfy our need for a midnight snack. It is essential to try all dishes served at your bhawan canteen. At the end of the day if the world feels weary, annoyance always tastes better with a plate of maggi and a cup of tea.",
    },
    {
      display: "Radha Krishna Bhawan",

      img: "hcanteen.jpg",
      markers: [
        {
          name: "Radha Krishna Bhawan",
          coords: updateCoordinates([-7.25, 27.72]),
        },
      ],
      p1:
        "While some of the hostel canteens like Rajiv Bhawan, Ravindra and Govind Bhawan are better than most of the eateries, the other bhawan canteen are good enough to satisfy our need for a midnight snack. It is essential to try all dishes served at your bhawan canteen. At the end of the day if the world feels weary, annoyance always tastes better with a plate of maggi and a cup of tea.",
    },
    {
      display: "Cautley Bhawan",

      img: "hcanteen.jpg",
      markers: [
        { name: "Cautley Bhawan", coords: updateCoordinates([-7.62, 30.41]) },
      ],
      p1:
        "While some of the hostel canteens like Rajiv Bhawan, Ravindra and Govind Bhawan are better than most of the eateries, the other bhawan canteen are good enough to satisfy our need for a midnight snack. It is essential to try all dishes served at your bhawan canteen. At the end of the day if the world feels weary, annoyance always tastes better with a plate of maggi and a cup of tea.",
    },
    {
      display: "Ganga Bhawan",

      img: "hcanteen.jpg",
      markers: [
        { name: "Ganga Bhawan", coords: updateCoordinates([-5.93, 24.29]) },
      ],
      p1:
        "While some of the hostel canteens like Rajiv Bhawan, Ravindra and Govind Bhawan are better than most of the eateries, the other bhawan canteen are good enough to satisfy our need for a midnight snack. It is essential to try all dishes served at your bhawan canteen. At the end of the day if the world feels weary, annoyance always tastes better with a plate of maggi and a cup of tea.",
    },
    {
      display: "Govind Bhawan",

      img: "hcanteen.jpg",
      markers: [
        { name: "Govind Bhawan", coords: updateCoordinates([-40.37, 24.29]) },
      ],
      p1:
        "While some of the hostel canteens like Rajiv Bhawan, Ravindra and Govind Bhawan are better than most of the eateries, the other bhawan canteen are good enough to satisfy our need for a midnight snack. It is essential to try all dishes served at your bhawan canteen. At the end of the day if the world feels weary, annoyance always tastes better with a plate of maggi and a cup of tea.",
    },
    {
      display: "Azad Bhawan",

      img: "hcanteen.jpg",
      markers: [
        { name: "Azad Bhawan", coords: updateCoordinates([-28.37, 13.79]) },
      ],
      p1:
        "While some of the hostel canteens like Rajiv Bhawan, Ravindra and Govind Bhawan are better than most of the eateries, the other bhawan canteen are good enough to satisfy our need for a midnight snack. It is essential to try all dishes served at your bhawan canteen. At the end of the day if the world feels weary, annoyance always tastes better with a plate of maggi and a cup of tea.",
    },
    {
      display: "Rajiv Bhawan",
      img: "hcanteen.jpg",
      markers: [
        { name: "Rajiv Bhawan", coords: updateCoordinates([-12.5, 25.64]) },
      ],
      p1:
        "While some of the hostel canteens like Rajiv Bhawan, Ravindra and Govind Bhawan are better than most of the eateries, the other bhawan canteen are good enough to satisfy our need for a midnight snack. It is essential to try all dishes served at your bhawan canteen. At the end of the day if the world feels weary, annoyance always tastes better with a plate of maggi and a cup of tea.",
    },
    {
      display: "Ravindra Bhawan",
      img: "hcanteen.jpg",
      markers: [
        { name: "Ravindra Bhawan", coords: updateCoordinates([-30.68, 19.04]) },
      ],
      p1:
        "While some of the hostel canteens like Rajiv Bhawan, Ravindra and Govind Bhawan are better than most of the eateries, the other bhawan canteen are good enough to satisfy our need for a midnight snack. It is essential to try all dishes served at your bhawan canteen. At the end of the day if the world feels weary, annoyance always tastes better with a plate of maggi and a cup of tea.",
    },
    {
      display: "Jawahar Bhawan",
      img: "hcanteen.jpg",
      markers: [
        { name: "Jawahar Bhawan", coords: updateCoordinates([-31.75, 43.72]) },
      ],
      p1:
        "While some of the hostel canteens like Rajiv Bhawan, Ravindra and Govind Bhawan are better than most of the eateries, the other bhawan canteen are good enough to satisfy our need for a midnight snack. It is essential to try all dishes served at your bhawan canteen. At the end of the day if the world feels weary, annoyance always tastes better with a plate of maggi and a cup of tea.",
    },
    {
      display: "Sarojini Bhawan",
      img: "hcanteen.jpg",
      markers: [
        { name: "Sarojini Bhawan", coords: updateCoordinates([-29.25, 41.85]) },
      ],
      p1:
        "While some of the hostel canteens like Rajiv Bhawan, Ravindra and Govind Bhawan are better than most of the eateries, the other bhawan canteen are good enough to satisfy our need for a midnight snack. It is essential to try all dishes served at your bhawan canteen. At the end of the day if the world feels weary, annoyance always tastes better with a plate of maggi and a cup of tea.",
    },
    {
      display: "Kasturba Bhawan",

      img: "hcanteen.jpg",
      markers: [
        { name: "Kasturba Bhawan", coords: updateCoordinates([-21.56, 43.1]) },
      ],
      p1:
        "While some of the hostel canteens like Rajiv Bhawan, Ravindra and Govind Bhawan are better than most of the eateries, the other bhawan canteen are good enough to satisfy our need for a midnight snack. It is essential to try all dishes served at your bhawan canteen. At the end of the day if the world feels weary, annoyance always tastes better with a plate of maggi and a cup of tea.",
    },
  ],

  others: [
    {
      display: "Banks",

      img: "bank.jpg",
      markers: [
        { name: "SBI", coords: updateCoordinates([-31.18, 27.8]) },
        { name: "PNB", coords: updateCoordinates([-33.62, 23.55]) },
      ],
      p1:
        "Two banks are located on the campus; the State Bank of India and the Punjab National Bank. Both the banks offer Centralised Banking Services(CBS), ATM facilities and internet banking. A number of ATMs from both the banks are scattered around the campus including two at the MAC, one in the main building and one near CBRI. On the day of counselling, both the banks set up camps near the Convocation Hall for opening the accounts of new entrants. Apart from this, branches of other government and private banks such as Axis, ICICI are present outside, in the city.",
    },
    {
      display: "Central Gym",

      img: "gym.jpg",
      markers: [
        { name: "Central Gym", coords: updateCoordinates([-23.62, 36.58]) },
      ],
      p1:
        "Central Gym is a two-storeyed building, the ground floor has gymming facilities allocated for boys, while the first floor provides the same for women. It is equipped with all the latest machines and boasts of a powerful music system. Trainers are available during the evening hours in the central gym as well as the gym in respective hostels.",
    },
    {
      display: "LBS",

      img: "lbs.jpg",
      markers: [{ name: "LBS", coords: updateCoordinates([-21.18, 27.85]) }],
      p1:
        "LBS is accredited as our institute’s athletic or jogging track cum cricket field.It is the biggest amongst institute’s three full-fledged stadiums.",
    },
    {
      display: "Hockey Stadium",

      img: "hockey.jpg",
      markers: [
        { name: "Hockey Stadium", coords: updateCoordinates([-19.5, 38.67]) },
      ],
      p1:
        "The hockey stadium is divided into two parts which serve as the hockey ground and the football ground respectively. One also witnesses friendly and fun football matches during ungodly morning hours which are usually followed by a breakfast treat.",
    },
    {
      display: "Hospital",

      img: "hospital.jpg",
      markers: [
        { name: "Hospital", coords: updateCoordinates([-39.4, 20.14]) },
      ],
      p1:
        "Started in the form of a dispensary for students and staff, the Institute Hospital is now a full-fledged hospital with around 50 beds, 24-hour emergency wards, and a host of other facilities and laboratories. The activities of the hospital include treatments and regular checkups. It is situated near the biotechnology department. The hospital is undergoing constant improvements to make healthcare more accessible and feasible for the residents of IIT Roorkee. ",
    },
    {
      display: "Computer Centre",

      img: "cc.jpg",
      markers: [
        { name: "Computer Centre", coords: updateCoordinates([-36.96, 30.77]) },
      ],
      p1:
        "The Institute Computer Centre is located behind the Electrical Department and also hosts the IMG Labs. When it comes to hard work during the last week of exam preparation, this is the place you should look forward to. Apart from this, the place also hosts the Information Superhighway Centre which plays an instrumental role in managing the internet connectivity of IIT Roorkee through a network of optical fibres. ",
    },
    {
      display: "Mahatma Gandhi Central Library",

      img: "library.jpg",
      markers: [{ name: "MGCL", coords: updateCoordinates([-28.21, 26.42]) }],
      p1:
        "The institute’s fully computerized ‘Mahatma Gandhi Central Library’ has a collection of over 50,000 books and periodicals and more than 20,000 e-books. The library subscribes to nearly 15,000 e-journals which are easily accessible online to anyone in the institute at the click of a button. Spread over an area of 90,000 sq.ft, the library’s stack area is over 1.5 km in length.",
    },
    {
      display: "Convocation Hall",

      img: "convo.jpg",
      markers: [
        {
          name: "Convocation Hall",
          coords: updateCoordinates([-18.53, 14.23]),
        },
      ],
      p1:
        "What once served as a hangar for the Second World War now stands proudly as the Convocation Hall of IITR. Located just within doors of the century gate, every year the Hall welcomes all the freshman for the registration process and the orientation programme whereas the same hall witnesses its students bestowed with a degree. The place is also a hub for various cultural events taking place during Thomso and Cognizance, Pehla Nasha, Jashn and the monthly movie screenings.",
    },
    {
      display: "Tinkering Lab",

      img: "tinkering.jpg",
      markers: [
        { name: "Tinkering Lab", coords: updateCoordinates([-33.4, 38.34]) },
      ],
      p1:
        "Tinkering Lab, as the name suggests is a place where one can tinker around with ideas. The double storeyed lab which houses two auditoriums, seminar rooms and various other facilities like 3-D printing, serves as an ideal place to shape up raw ideas. It was created to facilitate innovation in college, and it is accessible to all the students to test, validate and transform their ideas into new products and services.",
    },
    {
      display: "TIDES Incubation Centre",

      img: "tides.jpg",
      markers: [{ name: "TIDES", coords: updateCoordinates([-33.5, 36.62]) }],
      p1:
        "To further enhance the entrepreneurship environment in the campus, the Technology Innovation & Development of Entrepreneurship Support (TIDES) was established along with the tinkering labs. It provides all the support required to transform well thought-out ideas into real-time engineering products, be it financial, networking or guidance. Some of the start-ups incubated by TIDES are Log9, Contree, The Indian Iris, Transporter and Prago.",
    },
    {
      display: "Post Office",

      img: "post.jpg",
      markers: [
        { name: "Post Office", coords: updateCoordinates([-33.18, 23.52]) },
      ],
      p1:
        "The campus has a post office within its premises which is situated near the U.G. club. The post office is well equipped to cater to the basic necessities of the students such as sending speed posts, receiving parcels etc. The post office works on all weekdays from 9 A.M. to 5 P.M., and on Saturdays from 9 A.M. to 2 P.M. There is also a larger Head Post Office located in the Civil Lines but you rarely need to go there during your college life.",
    },
    {
      display: "Lecture Hall Complex",

      img: "lhc.jpg",
      markers: [{ name: "LHC", coords: updateCoordinates([-30.25, 22.54]) }],
      p1:
        "The LHC or Lecture Hall Complex is a hub for lectures, tutorials, MTEs for the undergrads that also cover the newly admitted ‘fachhas’ (lingo for freshman). The LHC also caters for various events during Thomso and Cognizance. Once stood in solitary, the complex is now has a Lecture Hall, LHC and another extension which is in the final stages of construction.",
    },
    {
      display: "Hostel Canteens",

      img: "hcanteen.jpg",
      markers: [{ name: "Hostel Canteens", coords: null }],
      p1:
        "While some of the hostel canteens like Rajiv Bhawan, Ravindra and Govind Bhawan are better than most of the eateries, the other bhawan canteen are good enough to satisfy our need for a midnight snack. It is essential to try all dishes served at your bhawan canteen. At the end of the day if the world feels weary, annoyance always tastes better with a plate of maggi and a cup of tea.",
    },
  ],
  clubs: [
    {
      display: "Multi Activity Center",
      img: "mac.jpg",
      markers: [{ name: "MAC", coords: updateCoordinates([-10.68, 29.1]) }],
      p1:
        "As the name suggests, this triple storeyed edifice harbors all the extra-curricular activities of the campus. From choreo auditions to panel discussions, myriads of events are organized here round the year. CCD, Amul, Subway and an open air theatre are also present in the building. This place also houses the Alaknanda Unit of Students' Club and the offices of Cultural Sections of IITR. Don't be surprised if you are treated with some music while you are chilling here, it's probably the Music Section practicing.",
    },
    {
      display: "Students' Activity Center",
      img: "sac1.jpg",
      markers: [{ name: "SAC ", coords: updateCoordinates([-21.56, 40.1]) }],
      p1:
        "The soon to be heart of many of the tech groups in the campus, the SAC building is the latest architectural feat of the institute. Widely spacious with lots of rooms, an open air theatre and even an observation deck, the building will house an extension of the Student's Club. This building is open round the clock and churns out brilliant student-made projects.",
    },
    {
      display: "UG Club/ Students Club",
      img: "sclub.jpg",
      markers: [
        {
          name: "UG Club/ Students Club",
          coords: updateCoordinates([-33.47, 25.95]),
        },
      ],
      p1:
        "UG Club, also known as the Students Club, acts as a major stress buster for students after a hectic day full of lectures and tutorials. Here students can fancy themselves with various indoor games like table tennis, pool, snooker, foosball etc. The place also houses a large screen LCD TV for enjoying live sports and other events with friends.",
    },
    {
      display: "The Sports Complex",
      img: "sports.jpg",
      markers: [
        {
          name: "The Sports Complex",
          coords: updateCoordinates([-23.71, 36.14]),
        },
      ],
      p1:
        "IITR has one of the best sports complexes among all the leading institutes in the country.  Be it the very common sports like badminton, athletics, tennis, swimming, hockey, cricket, squash, football, TT and volleyball, or sports like rowing, gymnastic, kho kho, yogic exercise and weightlifting, IIT Roorkee has a provision for each of them. IIT Roorkee is one of the earliest colleges in India to introduce sports like rowing over a century ago.",
    },
  ],
}

//markers list

//
