import React from "react"
import { Dimmer, Segment } from "semantic-ui-react"
import { MapContainer, Marker, useMap, Tooltip } from "react-leaflet"
import ResponsiveComponent from "../../components/responsive-component"
import Layout from "../../components/layout"
import L from "leaflet"
import "../../styles/places.css"
import { places } from "../../data/places"
import SEO from "../../components/seo"

function CustomOverlayLayer({ mapUrl }) {
  const lat = 10
  const lng = 10
  const zoom = 4
  const map = useMap()
  const w = 1500
  const h = 1125
  const southWest = map.unproject([0, h], map.getMaxZoom() - 1)
  const northEast = map.unproject([w, 0], map.getMaxZoom() - 1)
  const bounds = L.latLngBounds(southWest, northEast)
  L.imageOverlay(mapUrl, bounds).addTo(map)
  map.setMaxBounds(bounds)
  map.setView(L.latLng(lat, lng), zoom)
  return null
}

class Places extends ResponsiveComponent {
  state = {
    isFiltered: false,
    filters: {
      cafes: false,
      holy: false,
      clubs: false,
      hostels: false,
    },
    active: false,
    content: {
      heading: "",
      p: "",
      img: "",
    },
  }
  isVisible = type => {
    const { filters, isFiltered } = this.state
    if (!isFiltered || filters[type]) return true
    return false
  }

  handleShow = place => {
    const active = true
    const { content } = this.state
    content.heading = place.display
    content.p = place.p1
    content.img = place.img
    this.setState({ active, content })
  }

  handleHide = () => {
    this.setState({ active: false })
  }

  handleFilter = type => {
    const { filters } = this.state
    filters[type] = !filters[type]
    const isFiltered = Object.values(filters).filter(value => value).length
      ? true
      : false
    this.setState({ filters, isFiltered })
  }
  render() {
    // const data = useStaticQuery(query)
    const { active } = this.state
    let greenIcon, redIcon, blackIcon, greyIcon
    if (typeof window !== "undefined") {
      greenIcon = new L.Icon({
        iconUrl:
          "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      })

      redIcon = new L.Icon({
        iconUrl:
          "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      })

      blackIcon = new L.Icon({
        iconUrl:
          "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-black.png",
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      })

      greyIcon = new L.Icon({
        iconUrl:
          "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-grey.png",
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      })
      return (
        <Layout>
          <SEO title={"Places"} image={require("../../images/map.png")} />
          <div className="placecntr">
            <h2 className="dark-color-head">Campus Map</h2>
            <div className="mapcntr">
              <div className="mapdetails">
                <div className="filterplaces">
                  <div
                    className={`filter ${
                      this.state.filters["cafes"] ? "filter-active" : " "
                    }`}
                    onClick={() => this.handleFilter("cafes")}
                  >
                    Cafes
                  </div>
                  <div
                    className={`filter ${
                      this.state.filters["hostels"] ? "filter-active" : " "
                    }`}
                    onClick={() => this.handleFilter("hostels")}
                  >
                    Hostels
                  </div>
                  <div
                    className={`filter ${
                      this.state.filters["clubs"] ? "filter-active" : " "
                    }`}
                    onClick={() => this.handleFilter("clubs")}
                  >
                    Clubs
                  </div>
                  <div
                    className={`filter ${
                      this.state.filters["holy"] ? "filter-active" : " "
                    }`}
                    onClick={() => this.handleFilter("holy")}
                  >
                    Holy{" "}
                  </div>
                </div>
                {places.cafes.map(place => (
                  <div
                    className="placecard"
                    onClick={() => this.handleShow(place)}
                  >
                    <img
                      src={require(`../../images/places/${place.img}`)}
                      size="small"
                      className="placeimg"
                    />
                    <div className="placecontent">
                      <div>{place.display}</div>
                      <div className="temp tag2">Cafe</div>
                    </div>
                  </div>
                ))}
                {places.clubs.map(place => (
                  <div
                    className="placecard"
                    onClick={() => this.handleShow(place)}
                  >
                    <img
                      src={require(`../../images/places/${place.img}`)}
                      size="small"
                      className="placeimg"
                    />
                    <div className="placecontent">
                      <div>{place.display}</div>
                      <div className="temp tag2">Club</div>
                    </div>
                  </div>
                ))}
                {places.holy.map(place => (
                  <div
                    className="placecard"
                    onClick={() => this.handleShow(place)}
                  >
                    <img
                      src={require(`../../images/places/${place.img}`)}
                      size="small"
                      className="placeimg"
                    />
                    <div className="placecontent">
                      <div>{place.display}</div>
                      <div className="temp tag2">Holy</div>
                    </div>
                  </div>
                ))}
                {places.others.map(place => (
                  <div
                    className="placecard"
                    onClick={() => this.handleShow(place)}
                  >
                    <img
                      src={require(`../../images/places/${place.img}`)}
                      size="small"
                      className="placeimg"
                    />
                    <div className="placecontent">
                      <div>{place.display}</div>
                      <div className="temp tag2">Others</div>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  width: "60%",
                  height: "100%",
                  zIndex: "1",
                  backgroundColor: "white",
                }}
              >
                <Dimmer.Dimmable
                  as={Segment}
                  dimmed={active}
                  style={{
                    width: "100%",
                    height: "100%",
                    zIndex: "1",
                  }}
                >
                  <MapContainer
                    className="map"
                    center={[0, 0]}
                    zoom={10}
                    scrollWheelZoom={false}
                    style={{
                      width: "100%",
                      height: "100%",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                    minZoom={3}
                    maxZoom={6}
                    crs={L.CRS.Simple}
                  >
                    {this.isVisible("cafes") &&
                      places.cafes.map(place => {
                        return place.markers.map(marker => {
                          return marker.coords ? (
                            <Marker
                              position={marker.coords}
                              icon={redIcon}
                              eventHandlers={{
                                click: () => this.handleShow(place),
                              }}
                            >
                              <Tooltip>{marker.name}</Tooltip>
                            </Marker>
                          ) : null
                        })
                      })}
                    {this.isVisible("holy") &&
                      places.holy.map(place => {
                        return place.markers.map(marker => {
                          if (marker.coords)
                            return (
                              <Marker
                                position={marker.coords}
                                icon={greenIcon}
                                eventHandlers={{
                                  click: () => this.handleShow(place),
                                }}
                              >
                                <Tooltip>{marker.name}</Tooltip>
                              </Marker>
                            )
                        })
                      })}
                    {this.isVisible("hostels") &&
                      places.hostels.map(place => {
                        return place.markers.map(marker => {
                          if (marker.coords)
                            return (
                              <Marker
                                position={marker.coords}
                                icon={greyIcon}
                                eventHandlers={{
                                  click: () => this.handleShow(place),
                                }}
                              >
                                <Tooltip>{marker.name}</Tooltip>
                              </Marker>
                            )
                        })
                      })}
                    {this.isVisible("others") &&
                      places.others.map(place => {
                        return place.markers.map(marker => {
                          if (marker.coords)
                            return (
                              <Marker
                                position={marker.coords}
                                eventHandlers={{
                                  click: () => this.handleShow(place),
                                }}
                              >
                                <Tooltip>{marker.name}</Tooltip>
                              </Marker>
                            )
                        })
                      })}
                    {this.isVisible("clubs") &&
                      places.clubs.map(place => {
                        return place.markers.map(marker => {
                          if (marker.coords)
                            return (
                              <Marker
                                position={marker.coords}
                                icon={blackIcon}
                                eventHandlers={{
                                  click: () => this.handleShow(place),
                                }}
                              >
                                <Tooltip>{marker.name}</Tooltip>
                              </Marker>
                            )
                        })
                      })}
                    <CustomOverlayLayer
                      mapUrl={require("../../images/map.png")}
                    />
                  </MapContainer>
                  <Dimmer active={active} verticalAlign="top">
                    <div className="places-overlay">
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <img
                          className="cross"
                          src={require("../../images/icons/close-w.png")}
                          onClick={this.handleHide}
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </div>
                      <div className="places-heading">
                        {this.state.content.heading}
                      </div>
                      <div className="places-para">{this.state.content.p}</div>
                      <div className="place-img">
                        <img
                          src={
                            this.state.content.img
                              ? require(`../../images/places/${this.state.content.img}`)
                              : null
                          }
                        />
                      </div>
                    </div>
                  </Dimmer>
                </Dimmer.Dimmable>
              </div>
            </div>
          </div>
        </Layout>
      )
    }
    return <>HLLO</>
  }
}

export default Places
